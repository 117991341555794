<template>
    <div class="jp-common-layout-center jp-flex-main">
        <el-form size="small" :inline="true"  class="query-form"  ref="searchForm" :model="searchForm" @keyup.enter.native="refreshList()" @submit.native.prevent>
            <el-form-item prop="name">
                <el-input size="small"  v-model="searchForm.name"   placeholder="请输入文件名称"   clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button  type="primary" @click="searchList()" size="small" icon="el-icon-search">查询</el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white top">
            <div class="flex_b_t">
                <el-menu  :default-active="searchForm.apprStatusValue" class="el-menu-demo flex_l_c" mode="horizontal" @select="handleSelectTab">
                    <el-menu-item index="1">待审核</el-menu-item>
                    <el-menu-item index="3">过期未审</el-menu-item>
                    <el-menu-item index="2">审核历史</el-menu-item>
                </el-menu>
                <div v-if="searchForm.apprStatusValue=='1'">
                    <el-button size="small" type="primary" plain @click="update('',1)"
                            :disabled="dataListSelections.length==0">同意
                    </el-button>
                    <el-button size="small" type="primary" plain @click="update('',2)"
                            :disabled="dataListSelections.length==0">驳回
                    </el-button>
                </div>
            </div>
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 320px)"
                    @selection-change="selectionChangeHandle"
                    class="table" ref="tableBox">
                <el-table-column type="selection" width="50" fixed/>
                <el-table-column prop="status" label="序号" width="50">
                    <template slot-scope="scope">
                        <span>{{(pageNo-1)*pageSize+(scope.$index + 1)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="userName" label="借阅人" show-overflow-tooltip />
                <el-table-column prop="applyDate" label="借阅时间"  show-overflow-tooltip />
                <el-table-column prop="borrowingPurpose"  label="借阅目的" show-overflow-tooltip />
                <el-table-column prop="remarks"  label="备注" show-overflow-tooltip />
                <el-table-column prop="fileName"  label="题名" show-overflow-tooltip />
                <el-table-column prop="archivalCode"  label="档号" show-overflow-tooltip />
                <el-table-column label="审批结果" v-if="searchForm.apprStatusValue=='2'">
                    <template slot-scope="scope" >
                        {{ $dictUtils.getDictLabel("label_Index_state", scope.row.apprStatusValue, '-') }}
                    </template>
                </el-table-column>
                <el-table-column
                        header-align="center"
                        width="220"
                        align="center"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" plain v-if="searchForm.apprStatusValue=='1'" @click="update(scope.row.id,1)">同意</el-button>
                        <el-button size="mini" type="primary" plain v-if="searchForm.apprStatusValue=='1'" @click="update(scope.row.id,2)">驳回</el-button>
                        <el-button size="mini" type="primary" plain @click="view(scope.row.fileId)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!-- 查看 -->
        <FileDetails ref="fileDetails" @refreshDataList="refreshList"></FileDetails>
    </div>
</template>

<script>
    import FileDetails from '../../fileManagement/fileManagement/fileDetails'
    export default {
        components: {
            FileDetails,
        },
        data () {
            return {
                searchForm: {
                    name:"",
                    apprStatusValue:"1"
                },
                dataList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                index: '1',
                dataListSelections: [],
                loading: false,
            }
        },
        mounted() {
            this.refreshList();
            // this.getFileList();
        },
        methods: {
            searchList() {
                this.pageNo = 1;
                this.pageSize = 10;
                this.total = 0;
                this.refreshList();
            },
            // 获取数据列表
            refreshList() {
                this.loading = true
                this.$axios(this.api.zlzs.queryAllByLimitApproval, {
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                    ...this.searchForm
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.dataList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.$refs.tableBox.doLayout();
                        this.loading = false
                    }
                })
            },
            // tab切换
            handleSelectTab(index) {
                this.searchForm.apprStatusValue = index;
                this.refreshList()
            },
            // 查看
            view(id) {
                this.$refs.fileDetails.init(id, 'gather')
            },
            //更新
            update(id,apprStatusValue){
                let pd = "确定同意所选项吗?"
                if(apprStatusValue == 2){
                    pd = "确定驳回所选项吗?"
                }
                this.$confirm(pd, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let ids='';
                    if(id==''){
                        ids = this.dataListSelections.map(item => {
                                return item.id
                        }).join(',')
                    }else{
                        ids =id
                    }

                    this.$axios(this.api.zlzs.borrowUpdateById, {
                        'ids': ids,
                        'apprStatusValue': apprStatusValue,
                    }, 'put').then(data => {
                        if (data && data.status) {
                            this.$message.success(data.msg)
                            this.refreshList()
                        }
                    })
                })
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },
        }
    }
</script>

<style scoped>

</style>
